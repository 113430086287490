import React, {useEffect} from 'react';
import classnames from 'classnames';
import compact from 'lodash/compact';
import {isIE} from 'react-device-detect';
import AccountDetails from 'candidate/providers/account/details';
import paths from 'candidate/shared/paths';
import styles from './styles.scss';

export const Shimmer = ({className, ...rest}) => (
  <a
    className={classnames(compact([styles.shimmer, className]))}
    data-ui="company-logo"
    href={paths.root()}
    {...rest}
  />
);

if (isIE) {
  require(/* webpackMode: "lazy", webpackChunkName: "objectFitPolyfill" */ 'objectFitPolyfill');
}

export const AccountLogo = ({account, className, ...rest}) => {
  useEffect(() => {
    window.setTimeout(
      () => window.requestAnimationFrame(() => window.objectFitPolyfill && window.objectFitPolyfill()),
      0
    );
  });

  if (!account) {
    return <Shimmer className={className} {...rest} />;
  }

  const {logo, name} = account;
  const _class = classnames(compact([styles.logo, className, {[styles.limited]: !!logo}]));

  let img;
  if (!logo) {
    img = <span>{name}</span>;
  } else {
    img = <img alt={name} data-object-fit="contain" src={logo} />;
  }

  return (
    <a className={_class} data-ui="company-logo" href={paths.root()} {...rest}>
      {img}
    </a>
  );
};

export default props => (
  <AccountDetails.Consumer>
    {({accountDetails: account}) => <AccountLogo account={account} {...props} />}
  </AccountDetails.Consumer>
);
