import i18n from 'candidate/i18n';
import {Fields} from 'candidate/shared/enums';
import {MAX_FILE_SIZE, SUPPORTED_DOC_TYPES} from './constants';

const getTranslationForField = ({field, language, translations}) => {
  if (!language) {
    return;
  }

  const translation = translations.find(t => t[language]);
  return translation && translation[language] && translation[language][field];
};

const getTranslatedDisplayValue = ({
  defaultAccountLanguage,
  defaultDisplayedValue,
  fieldTranslationKey = 'label',
  language,
  translations
}) => {
  if (!translations || !Array.isArray(translations)) {
    return defaultDisplayedValue;
  }

  const jobLanguageTranslation = getTranslationForField({field: fieldTranslationKey, language, translations});
  const accountDefaultLanguageTranslation = getTranslationForField({
    field: fieldTranslationKey,
    language: defaultAccountLanguage,
    translations
  });

  return jobLanguageTranslation || accountDefaultLanguageTranslation || defaultDisplayedValue;
};

const getChoiceDisplayValue = ({defaultAccountLanguage, defaultDisplayedValue, language, translations}) =>
  getTranslatedDisplayValue({
    defaultAccountLanguage,
    defaultDisplayedValue,
    fieldTranslationKey: 'body',
    language,
    translations
  });

const getFieldLabelValue = ({defaultAccountLanguage, defaultDisplayedValue, language, translations}) =>
  getTranslatedDisplayValue({
    defaultAccountLanguage,
    defaultDisplayedValue,
    fieldTranslationKey: 'label',
    language,
    translations
  });

const custom = ({defaultAccountLanguage, field, fieldSetting, language = i18n.language}) => {
  if (!field || !Fields.TYPES[field.type]) {
    return;
  }

  let newField = {};
  switch (Fields.TYPES[field.type]) {
    case 'multiple':
    case 'dropdown':
      newField = {
        options:
          field &&
          field.choices &&
          field.choices.map &&
          field.choices.map(c => ({
            value: getChoiceDisplayValue({
              defaultAccountLanguage,
              defaultDisplayedValue: c.body,
              language,
              translations: c.translations
            })
          })),
        singleOption: field.single_answer
      };
      break;
    case 'file':
      newField = {
        maxFileSize: MAX_FILE_SIZE,
        supportedFileTypes: SUPPORTED_DOC_TYPES
      };
      break;
    default:
      break;
  }
  return {
    ...newField,
    id: field.id,
    label: getFieldLabelValue({
      defaultAccountLanguage,
      defaultDisplayedValue: field.label,
      language,
      translations: field.translations
    }),
    required: fieldSetting === 'required',
    type: Fields.TYPES[field.type]
  };
};

export default custom;
