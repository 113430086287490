import React, {useContext} from 'react';
import JobLocaleProvider from 'candidate/providers/locale/job/container';
import AccountProvider from './account/container';
import AccountProviderDetails from './account/details/container';
import AccountDetailsPreviewProvider from './account/details/preview';
import FlashProvider from './flash/container';
import GdprPreviewProvider from './gdpr/preview/container';
import JobProvider from './job/container';
import FormPreviewProvider from './job/form/preview/container';
import JobPreviewProvider from './job/preview/container';
import JobsCountProvider from './jobs/count/container';
import SearchProvider from './jobs/search/container';
import CareersLocale from './locale/careers';
import CareersLocaleProvider from './locale/careers/container';
import RecaptchaProvider from './recaptcha/container';
import ShortcodeProvider from './shortcode/container';
import SourcedCandidateProvider from './sourcedCandidate/container';

export const Providers = ({children}) => (
  <AccountProvider key={useContext(CareersLocale).locale}>
    <FlashProvider>
      <RecaptchaProvider>
        <ShortcodeProvider>
          <SourcedCandidateProvider>
            <JobsCountProvider>
              <AccountProviderDetails>
                <JobProvider>
                  <SearchProvider>{children}</SearchProvider>
                </JobProvider>
              </AccountProviderDetails>
            </JobsCountProvider>
          </SourcedCandidateProvider>
        </ShortcodeProvider>
      </RecaptchaProvider>
    </FlashProvider>
  </AccountProvider>
);

const WithLocale = ({children}) => (
  <CareersLocaleProvider>
    <Providers>{children}</Providers>
  </CareersLocaleProvider>
);

export const FormPreviewProviders = ({children}) => (
  <AccountProvider>
    <AccountProviderDetails>
      <FormPreviewProvider>
        <JobLocaleProvider>{children}</JobLocaleProvider>
      </FormPreviewProvider>
    </AccountProviderDetails>
  </AccountProvider>
);

export const JobPreviewProviders = ({children}) => (
  <AccountProvider>
    <AccountProviderDetails>
      <JobPreviewProvider>
        <JobLocaleProvider>{children}</JobLocaleProvider>
      </JobPreviewProvider>
    </AccountProviderDetails>
  </AccountProvider>
);

export const CareersPreviewProviders = ({children}) => (
  <AccountProvider>
    <AccountDetailsPreviewProvider>{children}</AccountDetailsPreviewProvider>
  </AccountProvider>
);

const GdprPreviewProviders = ({children}) => (
  <AccountProvider key={useContext(CareersLocale).locale}>
    <AccountProviderDetails>
      <GdprPreviewProvider>{children}</GdprPreviewProvider>
    </AccountProviderDetails>
  </AccountProvider>
);

export const GdprPreviewProvidersWithLocale = ({children}) => (
  <CareersLocaleProvider>
    <GdprPreviewProviders>{children}</GdprPreviewProviders>
  </CareersLocaleProvider>
);

export default WithLocale;
