/* eslint-disable react/jsx-no-constructed-context-values */
import React, {useCallback, useContext, useEffect, useState} from 'react';
import api from 'candidate/api';
import Account from 'candidate/providers/account';
import AccountDetails from '../details';

const description = [
  '________________________________________',
  '______________________________',
  '_______________________________________________________'
].join('<br /><br />');

const detailsWithSpecificDescription = {
  eeoc: false,
  gdpr: null,
  mailbox: {
    email: '_____'
  },
  overview: {
    description
  }
};

const AccountDetailsPreviewProvider = React.memo(({children}) => {
  const account = useContext(Account);
  const [accountDetails, setAccountDetails] = useState();

  useEffect(() => {
    api
      .account(account)
      .details()
      .then(accDetails => {
        setAccountDetails({
          ...accDetails,
          details: {
            ...(accDetails && accDetails.details),
            ...detailsWithSpecificDescription
          },
          languages: {
            default: 'en',
            options: ['en']
          }
        });
      });
  }, []);

  const updateAccountDetails = useCallback(accountData => setAccountDetails({...accountDetails, ...accountData}), [
    accountDetails
  ]);

  return <AccountDetails.Provider value={{accountDetails, updateAccountDetails}}>{children}</AccountDetails.Provider>;
});

export default AccountDetailsPreviewProvider;
