import {Fields} from 'candidate/shared/enums';
import {MAX_FILE_SIZE, SUPPORTED_DOC_TYPES} from './constants';

const question = field => {
  if (!field || !Fields.TYPES[field.type] || field.enabled === false) {
    return;
  }

  const choicesReducer = (options, choice) => {
    if (choice._destroy === 1) {
      return options;
    }
    const option = {value: choice.body};
    return [...options, option];
  };

  let newField = {};
  if (Fields.TYPES[field.type] === 'multiple' || Fields.TYPES[field.type] === 'dropdown') {
    newField = {
      options: field.choices_attributes.reduce(choicesReducer, []),
      singleOption: field.single_answer
    };
  }

  switch (Fields.TYPES[field.type]) {
    case 'multiple':
    case 'dropdown':
      newField = {
        options: field.choices_attributes.reduce(choicesReducer, []),
        singleOption: field.single_answer
      };
      break;
    case 'file':
      newField = {
        maxFileSize: MAX_FILE_SIZE,
        supportedFileTypes: SUPPORTED_DOC_TYPES
      };
      break;
    default:
      break;
  }

  return {
    ...newField,
    id: field.id,
    label: field.body,
    required: field.required,
    type: Fields.TYPES[field.type]
  };
};

export default question;
