/* eslint-disable react/jsx-no-constructed-context-values */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import Shortcode from 'candidate/providers/shortcode';

export class _JobPreviewProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {job: undefined};
    this.setData = this.setData.bind(this);
    this.observer = null;
  }

  componentDidMount() {
    window.addEventListener('message', this.setData);
    const [_subdomain, name, suffix] = window.location.hostname.split('.');
    if (name && suffix) {
      this.domain = `${name}.${suffix}`;
      this.sendMessage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.setData);
  }

  componentDidUpdate() {
    const {job} = this.state;

    if (job && !this.observer) {
      this.observer = new MutationObserver(() => {
        const height = document.body.scrollHeight;

        if (height) {
          window.parent.postMessage(JSON.stringify({height, type: 'height'}), this.getOrigin());
          this.observer.disconnect();
          this.observer = null;
        }
      });

      this.observer.observe(document.body, {attributes: true, childList: true, subtree: true});
    }
  }

  getOrigin() {
    const {subdomain} = this.props;
    return `https://${subdomain}.${this.domain}`;
  }

  sendMessage() {
    if (!window.parent) {
      return;
    }

    const {subdomain} = this.props;

    if (subdomain && this.domain) {
      window.parent.postMessage('loaded', this.getOrigin());
    }
  }

  setData(event) {
    if (!event.origin || !event.origin.endsWith(this.domain) || typeof event.data !== 'string' || !event.data) {
      return;
    }
    const data = JSON.parse(event.data);
    if (data.type !== 'preview') {
      return;
    }
    this.setState({job: data.job});
  }

  render() {
    const {job} = this.state;
    const {children} = this.props;

    return (
      <Shortcode.Provider value={job && (job.shortcode || job.id)}>
        <Job.Provider value={{job}}>{children}</Job.Provider>
      </Shortcode.Provider>
    );
  }
}

_JobPreviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
  subdomain: PropTypes.string.isRequired
};

const JobPreviewProvider = props => (
  <AccountDetails.Consumer>
    {({accountDetails}) =>
      accountDetails ? <_JobPreviewProvider subdomain={accountDetails.subdomain} {...props} /> : null
    }
  </AccountDetails.Consumer>
);

export default JobPreviewProvider;
