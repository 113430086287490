import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import i18n from 'candidate/i18n';
import {Fields} from 'candidate/shared/enums';
import {MAX_FILE_SIZE, SUPPORTED_DOC_TYPES, SUPPORTED_PHOTO_TYPES} from './constants';
import customField from './custom';
import questionField from './question';

/*
 *  This array is used just to render the education and
 *  experience empty modes but doens't show up in the form.
 */
const dummyFields = [
  {
    id: 'school',
    label: 'School',
    maxLength: 255,
    required: false,
    type: 'text'
  }
];

const staticField = (field, fieldSetting) => {
  let newField = {
    id: field.id,
    label: Fields.LABELS[field.id](),
    required: fieldSetting === 'required',
    type: Fields.TYPES[field.id]
  };
  if (field.id === 'avatar' || field.id === 'resume') {
    newField = {
      ...newField,
      maxFileSize: MAX_FILE_SIZE
    };
    Object.assign(
      newField,
      field.id === 'avatar' ? {supportedFileTypes: SUPPORTED_PHOTO_TYPES} : {supportedFileTypes: SUPPORTED_DOC_TYPES}
    );
  }

  return newField;
};

const getExtraFields = gdpr => {
  const fields = [];

  if (!gdpr) {
    return fields;
  }

  if (gdpr) {
    fields.push({
      id: 'gdpr',
      required: true,
      type: 'boolean'
    });
  }

  return fields;
};

const makeFieldReducer = ({defaultAccountLanguage, language, settings}) => (section, field) => {
  if (
    settings[field.field] === 'disabled' ||
    settings[field.id] === 'disabled' ||
    field.field === 'name' ||
    field.field === 'email'
  ) {
    return section;
  }

  let newField;
  if (!field.custom) {
    newField = staticField(field, settings[field.id]);

    if (field.id === 'phone') {
      Object.assign(newField, {
        helper: 'The hiring team may use this number to contact you about this job.'
      });
    }

    if (field.id === 'address') {
      Object.assign(newField, {
        helper: 'Include your city, region, and country, so that employers can easily manage your application.'
      });
    }

    Object.assign(newField, field.id === 'education' || field.id === 'experience' ? {fields: dummyFields} : null);
  } else {
    newField = customField({
      defaultAccountLanguage,
      field,
      fieldSetting: settings[field.id],
      language
    });
  }

  return newField ? [...section, newField] : section;
};

const transformDetailsSection = ({details, fieldReducer, form, questions}) => {
  if (isEmpty(details) && isEmpty(questions)) {
    return form;
  }

  const detailsFields = details.reduce(fieldReducer, []);
  const detailsQuestions = questions && compact(questions.map(q => questionField(q)));
  const detailsSection = {
    id: 'details',
    name: i18n.t('form.sections.details')
  };
  Object.assign(
    detailsSection,
    !isEmpty(detailsQuestions) ? {fields: detailsFields?.concat(detailsQuestions)} : {fields: detailsFields}
  );

  return [...form, detailsSection];
};

const transformer = ({defaultAccountLanguage, gdpr, language, questions, sections, settings} = {}) => {
  if (!sections || !settings) {
    return undefined;
  }

  /*
   * First name, last name and email are always mandatory
   * and cannot be removed from the application form.
   */
  const firstname = {
    id: 'firstname',
    label: Fields.LABELS.firstname(),
    required: true,
    type: 'text'
  };

  const lastname = {
    id: 'lastname',
    label: Fields.LABELS.lastname(),
    required: true,
    type: 'text'
  };

  const email = {
    id: 'email',
    label: Fields.LABELS.email(),
    required: true,
    type: 'email'
  };

  const fieldReducer = makeFieldReducer({defaultAccountLanguage, language, settings});

  // Personal Information section
  const personalInfoFields = sections?.personal_information?.reduce(fieldReducer, [firstname, lastname, email]);

  const personalInfoSection = {
    fields: personalInfoFields,
    id: 'personal',
    name: i18n.t('form.sections.personal')
  };

  let form = [personalInfoSection];

  // Profile section
  if (!isEmpty(sections.candidate_profile)) {
    const profileInfoFields = sections?.candidate_profile?.reduce(fieldReducer, []);
    const profileInfoSection = {
      fields: profileInfoFields,
      id: 'profile',
      name: i18n.t('form.sections.profile')
    };

    form = [...form, profileInfoSection];
  }

  // Application details section
  form = transformDetailsSection({details: sections?.application_details, fieldReducer, form, questions});

  const extraFields = getExtraFields(gdpr);

  if (!isEmpty(extraFields)) {
    form.push({
      fields: extraFields,
      id: 'privacy'
    });
  }

  return form;
};

export default transformer;
