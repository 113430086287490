/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import qs from 'query-string';
import Gdpr from './context';

const DEFAULTS = Object.freeze({
  appointed_representative: '[appointed_representative]',
  company_name: '[company_name]',
  data_retention_months: '[data_retention_months]',
  internal_data_processing_location: '[internal_data_processing_location]',
  privacy_contact_email: '[privacy_contact_email]',
  representative_contact_info: '[representative_contact_info]',
  trading_info: '[trading_info]'
});

const GdprPreviewProvider = ({children, search = window.location.search}) => {
  const gdpr = {
    latest: true,
    policy: {
      ...DEFAULTS,
      ...qs.parse(search || '')
    }
  };

  return <Gdpr.Provider value={gdpr}>{children}</Gdpr.Provider>;
};

export default GdprPreviewProvider;
