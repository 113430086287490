/* eslint-disable react/jsx-no-constructed-context-values */
import React, {Component} from 'react';
import i18n, {changeLanguage} from 'candidate/i18n';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import FormStructure from 'candidate/providers/job/form/structure';
import transformer from './transformer';

const LOCAL_ATS_PORT = '3443';

export class _FormPreviewProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {form: undefined, job: undefined};
    this.setData = this.setData.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.setData);
    const [subdomain, name, suffix] = window.location.hostname.split('.'); // eslint-disable-line no-unused-vars

    if (name && suffix) {
      this.domain = `${name}.${suffix}`;
      this.sendMessage();
    }

    if ([`workable.dev`, `lvh.me`].includes(window.location.hostname)) {
      this.domain = `${window.location.hostname}:${LOCAL_ATS_PORT}`;
      this.sendMessage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.setData);
  }

  sendMessage() {
    if (!window.parent) {
      return;
    }

    const subdomain = this.props.subdomain;

    if (subdomain && this.domain) {
      window.parent.postMessage('loaded', `https://${subdomain}.${this.domain}`);
    }
  }

  sendHeight() {
    if (!window.parent) {
      return;
    }

    const subdomain = this.props.subdomain;

    if (subdomain && this.domain) {
      window.parent.postMessage(
        {type: 'height', value: document.body.scrollHeight},
        `https://${subdomain}.${this.domain}`
      );
    }
  }

  setData(event) {
    if (!event.origin || !event.origin.endsWith(this.domain) || typeof event.data !== 'string' || !event.data) {
      return;
    }

    const data = JSON.parse(event.data);
    if (data.type !== 'preview') {
      return;
    }
    const setJobLanguage = jobLanguage =>
      Promise.resolve(jobLanguage && jobLanguage !== i18n.language && changeLanguage(jobLanguage));

    const job = data && data.job;
    const language = job && job.language;

    return setJobLanguage(language).then(() => {
      const {questions, sections, settings, subject_to_gdpr: gdpr} = data || {};
      const {defaultAccountLanguage} = this.props;
      this.setState(
        {
          form: transformer({
            defaultAccountLanguage,
            gdpr,
            language,
            questions,
            sections,
            settings
          }),
          job
        },
        () => this.sendHeight()
      );
    });
  }

  render() {
    const {form, job} = this.state;

    return (
      <FormStructure.Provider value={{form}}>
        <Job.Provider value={{job}}>{this.props.children}</Job.Provider>
      </FormStructure.Provider>
    );
  }
}

const FormPreviewProvider = props => (
  <AccountDetails.Consumer>
    {({accountDetails}) =>
      accountDetails ? (
        <_FormPreviewProvider
          defaultAccountLanguage={accountDetails?.languages?.default}
          subdomain={accountDetails.subdomain}
          {...props}
        />
      ) : null
    }
  </AccountDetails.Consumer>
);

export default FormPreviewProvider;
