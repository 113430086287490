import React, {useContext, useEffect, useState} from 'react';
import i18n, {changeLanguage} from 'candidate/i18n';
import Job from 'candidate/providers/job';
import CareersLocale from 'candidate/providers/locale/careers';
import Locale from './jobLocale';

const JobLocaleProvider = ({children}) => {
  const [locale, setLocale] = useState(i18n.language);
  const {job: {language} = {}} = useContext(Job);
  const {locale: careersLocale, setCareersLocale} = useContext(CareersLocale);

  useEffect(() => {
    const updateLocale = () => Promise.resolve(language === i18n.language ? undefined : changeLanguage(language));

    if (language) {
      updateLocale().then(() => setLocale(language));
    }
  }, [language]);

  useEffect(() => {
    return () => {
      if (careersLocale) {
        setCareersLocale(careersLocale);
      }
    };
  }, []);

  return <Locale.Provider value={locale}>{children instanceof Function ? children(locale) : children}</Locale.Provider>;
};

export default JobLocaleProvider;
